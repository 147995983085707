<template>
  <article id="wrap">
    <AppHeader>送信完了</AppHeader>

    <section class="contents pb_150">

      <div class="ta_c mt_20 mb_30"><img src="@/assets/img/buy/img_thank_you.png" alt="Thank you画像" class="thanks"></div>

      <div class="ta_c txt_12 mb_30">販売申請ありがとうございました。<br>
        販売方法について<br>
        運営から、連絡いたします。</div>

      <div class="bt_form mb_30"><RouterLink :to="{name: 'mypage'}">わたしのことに戻る</RouterLink></div>

    </section>

    <AppFooter fixed></AppFooter>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  components: { AppHeader, AppFooter },
}
</script>
